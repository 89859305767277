@use 'global/mixin' as m;
@use 'global/variable' as v;

#home {
	img {
		max-width: 100%;
	}
}

.p-index {
	&Mv {
		position: relative;
		overflow: hidden;
		@include m.sp {
			margin-top: 50px;
		}
		&::before {
			position: absolute;
			bottom: 0;
			left: calc(50% + 8px);
			z-index: +2;
			width: 10px;
			height: 75px;
			background: url(../../img/icon_scrolldown.png) no-repeat top left / contain;
			transform: translate(-50%,0);
			content: "";
			@include m.pc {
				width: 16px;
				height: 110px;
			}
		}
		&::after {
			@include m.pc {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 300px;
				background: linear-gradient(to bottom, #FFF 0%, transparent 100%);
				content: "";
			}
		}
		&_logo {
			@include m.sp {
				display: none;
			}
			@include m.pc {
				position: absolute;
				top: 16%;
				left: 50%;
				z-index: +1;
				width: 6%;
				max-width: 100px;
				transform: translate(-50%,0);
			}
		}
		&_catch {
			position: absolute;
			top: 10%;
			left: 50%;
			z-index: +1;
			width: 60%;
			transform: translate(-50%,0);
			@include m.pc {
				top: 50%;
				width: 20%;
				max-width: 344px;
				transform: translate(-50%,-50%);
			}
		}
		&Slider {
			$this: &;
			position: relative;
			overflow: hidden;
			&::before {
				@include m.pc {
					position: absolute;
					top: 0;
					left: 50%;
					z-index: +1;
					width: 30%;
					height: 100%;
					transform: translate(-50%,0);
					background: linear-gradient(to right, transparent 0%, rgba(#fff,.8) 50%, transparent 100%);
					content: "";
				}
			}
			.slick-slide,
			&_slide {
				display: flex !important;
				flex-wrap: wrap;
			}
			&_left,
			&_right {
				overflow: hidden;
				background: #f0f0f0;
				@include m.sp {
					height: 40vh;
				}
				@include m.pc {
					// top: 0;
					width: 50%;
				}
				img {
					transform: scale(1.2);
					transition: transform 2s;
					object-fit: cover;
					@include m.sp {
						transform-origin: center bottom;
					}
				}
			}
			&_left {
				img {
					transform: scale(1.2) translateX(80px);
					@include m.pc {
						transform: scale(1.2) translateY(40px);
					}
				}
			}
			&_right {
				img {
					transform: scale(1.2) translateX(-80px);
					@include m.pc {
						transform: scale(1.2) translateY(-40px);
					}
				}
			}
			.slick-current,
			.slick-active {
				#{$this}_left img {
					transform: scale(1.2) translateX(0);
					@include m.pc {
						transform: scale(1.2) translateY(0);
					}
				}
				#{$this}_right img {
					transform: scale(1.2) translateX(0);
					@include m.pc {
						transform: scale(1.2) translateY(0);
					}
				}
			}
		}
	}

	&Mv--100th {
		@include m.sp {
			margin-top: 50px;
		}
	}

	&Lead {
		@include m.sp {
			padding: 50px 5% 0.1px;
			background: #f7f7f7 url(../../img/img_lead_sp.jpg) no-repeat top right / auto 100%;
		}
		@include m.pc {
			background: #f7f7f7 url(../../img/img_lead_pc.jpg) no-repeat bottom right / auto 100%;
		}
		&_inner {
			position: relative;
			margin: 0 auto;
			width: 100%;
			max-width: 1060px;
			@include m.pc {
				height: 500px;
			}
		}
		&_lead {
			font-family: "A1ゴシック M";
			font-size: 26px;
			line-height: 2;
			writing-mode: vertical-rl;
			@include m.sp {
				margin-bottom: 30px;
			}
			@include m.pc {
				position: absolute;
				top: 10%;
				left: 46%;
				font-size: 20px;
				white-space: nowrap;
			}
			html:lang(en) & {
				font-family: "Poppins", san-serif;
				font-weight: 500;
				font-size: 15px;
				@include m.pc {
					font-size: 30px;
				}
			}
			html:lang(zh-cn) &,
			html:lang(zh-tw) & {
				font-family: 'Noto Sans SC', sans-serif;
				font-weight: 500;
				@include m.pc {
					left: 34%;
				}
			}
		}
		&_txt {
			font-size: 16px;
			line-height: 2;
			@include m.sp {
				margin-bottom: 40px;
			}
			@include m.pc {
				position: absolute;
				top: 10%;
				left: 0;
				font-size: 16px;
				line-height: 3;
				writing-mode: vertical-rl;
				white-space: nowrap;
			}
			html:lang(en) & {
				font-family: "Poppins", san-serif;
				font-weight: 400;
				font-size: 14px;
				@include m.pc {
					font-size: 15px;
				}
			}
			html:lang(zh-cn) &,
			html:lang(zh-tw) & {
				font-family: 'Noto Sans SC', sans-serif;
				font-weight: 300;
			}
		}
	}

	&News {
		padding: 50px 0;
		background: #FFF no-repeat top 207px left / 410px auto;
		@include m.pc {
			padding: 100px 0;
			min-height: 850px;
			background-image: url(../../img/bg_news.jpg);
		}
		&_inner {
			margin: 0 auto;
			width: 90%;
			max-width: 1260px;
		}
		&_body {
			@include m.pc {
				position: relative;
				margin: 0 0 0 auto;
				width: 66%;
				max-width: 900px;
			}
		}
		&_ttl {
			margin-bottom: 30px;
			@include m.pc {
				margin-bottom: 60px;
			}
		}
		&_btn {
			@include m.pc {
				text-align: center;
			}
		}
		&List {
			&_item {
				display: block;
				position: relative;
				border-bottom: 1px solid #ccc;
				padding: 20px 20px 20px 0;
				color: #333;
				background: url(../img/icon_arrow_001.png) no-repeat right 8px center / 7px auto;
				line-height: 1.75;
				font-size: 14px;
				@include m.pc {
					display: flex;
					flex-wrap: wrap;
					align-items: flex-start;
					transition: background .4s;
					font-size: 16px;
				}
				&::before {
					position: absolute;
					bottom: -1px;
					left: 0;
					width: 45px;
					height: 1px;
					background: v.$red;
					content: "";
					@include m.pc {
						width: 60px;
						transition: width .4s;
					}
				}
				// &:hover {
				// 	@include m.pc {
				// 		background-position: right 0 center;
				// 		text-decoration: none;
				// 	}
				// 	&::before {
				// 		@include m.pc {
				// 			width: 100%;
				// 		}
				// 	}
				// }
			}
		}
		&Item {
			$this: &;
			&_date {
				display: inline-block;
				margin-right: 16px;
				font-family: 'Heebo', sans-serif !important;
				font-weight: bold;
				// @include m.pc {
				// 	width: 102px;
				// }
			}
			&_category {
				display: inline-block;
				margin-left: 16px;
				// width: 84px;
				// line-height: 24px;
				// background: v.$lightgreen;
				font-size: 12px;
				text-align: center;
				@include m.pc {
					font-size: 14px;
				}
				> img {
					vertical-align: middle;
				}
			}
			&_ttl {
				// @include m.pc {
				// 	width: calc(100% - 202px);
				// }
			}
			&_link {
				color: #333;
				&:hover {
					@include m.pc {
						text-decoration: underline;
					}
				}
			}
		}
		&TabList {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 30px;
			@include m.pc {
				margin-bottom: 60px;
			}
			&_item {
				border-top: 3px solid #FFF;
				border-bottom: 1px solid #CCC;
				width: calc(100% / 3);
				line-height: 40px;
				color: #333;
				font-size: 14px;
				font-weight: bold;
				text-align: center;
				@include m.pc {
					font-size: 16px;
				}
				&.is-active,
				&--active {
					border: 1px solid #CCC;
					border-top: 3px solid v.$lightgreen;
					border-bottom: 1px solid #FFF;
					color: v.$lightgreen;
				}
			}
		}
	}

	&Reason {
		position: relative;
		@include m.pc {
			padding-bottom: 0;
		}
		&::before {
			@include m.pc {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 240px;
				background: #FFF;
				content: "";
			}
		}
		&_inner {
			position: relative;
			margin: 0 auto;
			max-width: 1260px;
		}
		&_ttl {
			margin-bottom: 30px;
			@include m.pc {
				margin-bottom: 60px;
			}
		}
		&_btn {
			@include m.pc {
				position: absolute;
				top: 40px;
				right: 0;
			}
		}
		&List {
			counter-reset: num;
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
			&_item {
				position: relative;
				border: 1px solid v.$red;
				border-radius: 0 10px 10px 10px;
				padding: 30px 5%;
				background: #FFF;
				text-align: center;
				@include m.sp {
					margin: 0 auto 30px;
					width: 90%;
				}
				@include m.pc {
					padding: 60px 5%;
					width: calc(33% - 40px);
				}
				&--100th {
					padding-right: 0;
					padding-left: 0;
					background: #FFF url(/img/bg_100th.png) no-repeat top center / 100% auto;
					@include m.pc {
						padding-bottom: 0;
					}
				}
				html:lang(en) & {
					@include m.pc {
						padding-right: 10px;
						padding-left: 10px;
					}
				}
				&::before {
					position: absolute;
					top: 6px;
					left: 4px;
					z-index: +1;
					color: #FFF;
					font-size: 14px;
					font-weight: bold;
					counter-increment: num;
					content: "0" counter(num);
					@include m.pc {
						left: 6px;
						font-size: 20px;
					}
				}
				&::after {
					position: absolute;
					top: 0;
					left: 0;
					border: 22px solid v.$red;
					border-right-color: transparent;
					border-bottom-color: transparent;
					content: "";
					@include m.pc {
						border-width: 30px;
					}
				}
				&:nth-of-type(3n) {
					@include m.pc {
						margin-right: 0;
					}
				}
			}
			&_ttl {
				margin-bottom: 14px;
				color: #333;
				font-size: 24px;
				font-family: "Sharoa Pro ExtraBold";
				font-weight: bold;
				letter-spacing: .1em;
				line-height: 1;
				@include m.pc {
					margin-bottom: 20px;
					font-size: 40px;
				}
				> span {
					color: v.$red;
				}
				&--100th {
					margin-bottom: 12px;
				}
			}
			&_txt {
				font-size: 14px;
				line-height: 1.5;
				@include m.pc {
					font-size: 16px;
					line-height: 2;
				}
				> span {
					color: v.$red;
				}
			}
			&_pic {
				margin: 0 auto 8px;
				width: 112px;
			}
		}
	}

	&Topics {
		padding-right: 0;
		padding-bottom: 0;
		padding-left: 0;
		overflow: hidden;
		&_ttl {
			margin-bottom: 30px;
			@include m.pc {
				margin-bottom: 60px;
			}
		}
		&List {
			$this: &;
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin: 0 -2px;
			}
			&_item {
				display: block;
				position: relative;
				overflow: hidden;
				margin-bottom: 4px;
				font-size: 18px;
				font-weight: bold;
				@include m.pc {
					margin: 0 2px;
					width: calc(20% - 4px);
					font-size: 24px;
				}
				&::before {
					position: absolute;
					bottom: 15px;
					right: 15px;
					z-index: +1;
					border-radius: 50%;
					width: 25px;
					height: 25px;
					background: v.$red url(../img/icon_arrow_001_on.png) no-repeat center / 5px auto;
					content: "";
					@include m.pc {
						bottom: 60px;
						right: 30px;
						width: 30px;
						height: 30px;
						background-size: 7px auto;
					}
				}
				&:hover {
					@include m.pc {
						text-decoration: none;
					}
					#{$this}_pic {
						@include m.pc {
							transform: scale(1.2);
						}
					}
				}
				&:last-of-type {
					margin-bottom: 0;
				}
			}
			&_pic {
				width: 100%;
				@include m.pc {
					transition: transform .8s;
				}
			}
			&_ttl {
				position: absolute;
				top: 30px;
				left: 15px;
				padding-bottom: 20px;
				color: #FFF;
				@include m.pc {
					top: 60px;
					left: 30px;
				}
				&::before {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 30px;
					height: 2px;
					background: v.$red;
					content: "";
					@include m.pc {
						width: 60px;
						height: 3px;
					}
				}
			}
		}
	}

	&Group {
		position: relative;
		border-radius: 20px;
		margin: 0 auto;
		padding: 35px 5%;
		background: v.$red;
		color: #FFF;
		@include m.pc {
			padding: 70px 80px 70px 340px;
			width: 90%;
			max-width: 1260px;
		}
		&_ttl {
			margin-bottom: 30px;
			font-size: 24px;
			font-weight: bold;
			letter-spacing: .1em;
			text-align: center;
			@include m.pc {
				margin-bottom: 60px;
				font-size: 50px;
			}
			&::before {
				width: 122px;
				height: 96px;
				background: url(../img/logo.png) no-repeat center / contain;
				content: "";
				@include m.sp {
					display: block;
					margin: 0 auto 30px;
				}
				@include m.pc {
					position: absolute;
					top: 0;
					left: 80px;
					width: 200px;
					height: 100%;
				}
			}
			> span {
				display: block;
				margin-top: 10px;
				font-size: 14px;
				letter-spacing: .05em;
				@include m.pc {
					margin-top: 20px;
					font-size: 16px;
				}
			}
		}
		&_btn {
			margin-right: auto;
			margin-left: auto;
		}
		&List {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 30px;
			&_item {
				position: relative;
				z-index: +1;
				width: 48%;
				@include m.pc {
					width: 30%;
				}
				&:nth-of-type(n+3) {
					@include m.sp {
						margin-top: 1rem;
					}
				}
				> img {
					width: 100%;
				}
			}
		}
	}

	&Contact {
		text-align: center;
	}

	&Recruit {
		position: relative;
		@include m.sp {
			padding-bottom: 120.8%;
			background: url(../../img/bg_recruit_sp.jpg) no-repeat top left / cover;
		}
		@include m.pc {
			margin: 0 auto;
			padding: 100px 5%;
			max-width: 1600px;
			height: 500px;
			background: url(../../img/bg_recruit_pc.jpg) no-repeat center right / cover;
		}
		&_inner {
			@include m.pc {
				position: absolute;
				top: 50%;
				right: 0;
				width: 650px;
				transform: translate(0,-50%);
			}
		}
		&_ttl {
			@include m.sp {
				position: absolute;
				top: 50px;
				left: 50%;
				transform: translate(-50%,0);
			}
			@include m.pc {
				margin-bottom: 60px;
			}
		}
		&_btn {
			min-width: 300px;
			@include m.sp {
				position: absolute;
				bottom: 50px;
				left: 50%;
				transform: translate(-50%,0);
			}
			@include m.pc {
				width: 300px;
			}
		}
	}

	&Bnr {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		&_item {
			@include m.sp {
				margin: 0 7px 14px;
				width: calc(50% - 14px);
			}
			@include m.pc {
				margin: 0 15px 30px;
			}
		}
	}
}

// eir上書き関連
// .p-indexNews {
// 	#yearSelect {
// 		display: none;
// 	}
// 	#announcement {
// 		position: relative;
// 		overflow: hidden;
// 		height: 460px;
// 		&::before {
// 			position: absolute;
// 			bottom: 0;
// 			left: 0;
// 			z-index: +1;
// 			width: 100%;
// 			height: 100px;
// 			background: linear-gradient(to top, #fff 10%, transparent 100%);
// 			pointer-events: none;
// 			content: "";
// 		}
// 	}
// }
.tabInfo.p-indexNewsTabList {
	a {
		@extend .p-indexNewsTabList_item;
		&.selected {
			@extend .p-indexNewsTabList_item--active;
		}
	}
}
.ir_lib_list {
	> li {
		@extend .p-indexNewsList_item;
		.day.en {
			@extend .p-indexNewsItem_date;
		}
		.cat {
			@extend .p-indexNewsItem_category;
		}
		.text {
			@extend .p-indexNewsItem_ttl;
			a {
				@extend .p-indexNewsItem_link;
			}
		}
	}
}