@use 'global/mixin' as m;
@use 'global/variable' as v;

$irImg: "../../ir/img/";

.p-ir {
	&_ttl {
		margin-bottom: 40px;
		font-family: "Sharoa Pro ExtraBold";
		font-size: 28px;
		letter-spacing: .1em;
		line-height: 1;
		@include m.sp {
			text-align: center;
		}
			@include m.pc {
			font-size: 50px;
		}
		> em {
			color: v.$red;
		}
		> span {
			display: block;
			margin-top: .75em;
			color: v.$red;
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0;
			@include m.pc {
				font-size: 20px;
			}
		}
	}
	&_subttl {
		position: relative;
		margin-bottom: 40px;
		padding-bottom: 20px;
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		@include m.pc {
			font-size: 26px;
		}
		&::before {
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 60px;
			height: 3px;
			background: v.$red;
			transform: translate(-50%,0);
			content: "";
		}
	}
	&Related {
		padding: 50px 0;
		background: #fff;
		text-align: center;
		@include m.pc {
			padding: 100px 0;
		}
		&_ttl {
			margin-bottom: 30px;
			@include m.pc {
				margin-bottom: 60px;
			}
		}
		&List {
			$this: &;
			margin-bottom: 60px;
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin: 0 -20px 100px;
			}
			&_item {
				display: block;
				position: relative;
				overflow: hidden;
				margin-bottom: 4px;
				font-size: 18px;
				font-weight: bold;
				@include m.pc {
					margin: 0 20px 20px;
					width: calc(50% - 40px);
					max-width: 400px;
					font-size: 24px;
				}
				&::before {
					position: absolute;
					bottom: 15px;
					right: 15px;
					z-index: +1;
					border-radius: 50%;
					width: 25px;
					height: 25px;
					background: v.$red url(../img/icon_arrow_001_on.png) no-repeat center / 5px auto;
					content: "";
					@include m.pc {
						bottom: 20px;
						right: 30px;
						width: 30px;
						height: 30px;
						background-size: 7px auto;
					}
				}
				&:hover {
					@include m.pc {
						text-decoration: none;
					}
					#{$this}_pic {
						@include m.pc {
							transform: scale(1.2);
						}
					}
				}
				&:last-of-type {
					margin-bottom: 0;
				}
			}
			&_pic {
				width: 100%;
				@include m.pc {
					transition: transform .8s;
				}
			}
			&_ttl {
				position: absolute;
				top: 30px;
				left: 15px;
				padding-bottom: 20px;
				color: #FFF;
				@include m.pc {
					top: 60px;
					left: 30px;
				}
				&::before {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 30px;
					height: 2px;
					background: v.$red;
					content: "";
					@include m.pc {
						width: 60px;
						height: 3px;
					}
				}
			}
		}
	}
	&ReasonList {
		margin-bottom: 40px;
		counter-reset: num;
		@include m.pc {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 80px;
		}
		&_item {
			position: relative;
			border: 1px solid v.$red;
			border-radius: 0 10px 10px 10px;
			background: #FFF;
			text-align: center;
			word-break: break-all;
			@include m.sp {
				margin: 0 auto 30px;
				width: 90%;
			}
			@include m.pc {
				margin: 0 12px 24px;
				width: calc(33% - 24px);
				min-width: 250px;
			}
			&::before {
				position: absolute;
				top: 6px;
				left: 4px;
				z-index: +1;
				color: #FFF;
				font-size: 14px;
				font-weight: bold;
				counter-increment: num;
				content: "0" counter(num);
				pointer-events: none;
				@include m.pc {
					left: 6px;
					font-size: 20px;
				}
			}
			&::after {
				position: absolute;
				top: 0;
				left: 0;
				border: 22px solid v.$red;
				border-right-color: transparent;
				border-bottom-color: transparent;
				pointer-events: none;
				content: "";
				@include m.pc {
					border-width: 30px;
				}
			}
			&:nth-of-type(3n) {
				@include m.pc {
					margin-right: 0;
				}
			}
		}
		&_link {
			display: block;
			padding: 30px 5%;
			color: #333;
			@include m.pc {
				padding: 60px 24px;
			}
			&:hover {
				@include m.pc {
					text-decoration: none;
				}
			}
		}
		&_ttl {
			margin-bottom: 14px;
			color: #333;
			font-size: 24px;
			font-family: "Sharoa Pro ExtraBold";
			font-weight: bold;
			letter-spacing: .1em;
			line-height: 1;
			@include m.pc {
				margin-bottom: 20px;
				font-size: 26px;
			}
			> span {
				color: v.$red;
			}
		}
		&_txt {
			font-size: 14px;
			line-height: 1.5;
			@include m.pc {
				font-size: 16px;
				line-height: 2;
			}
		}
	}
	&BtnList {
		@include m.pc {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
		&_btn {
			@include m.pc {
				margin: 0 10px 20px;
			}
		}
	}
}

// pir関連
#hqir {
	width: auto !important;
	.frameTable {
		width: auto !important;
	}
	.frameTable {
		display: block;
		> tbody,
		> tbody > tr,
		> tbody > tr > td,
		> tr > td {
			display: block;
		}
	}
}
.ir_rss {
	@include m.sp {
		margin-right: 5%;
	}
}
#ir_library {
	@include m.sp {
		margin: 0 5%;
	}
	* {
		box-sizing: content-box;
	}
}