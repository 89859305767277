@use 'global/mixin' as m;
@use 'global/variable' as v;

$companyImg: "../../company/img/";

.p-company {

	&_inner {
		margin: 0 auto;
		width: 90%;
		max-width: 1060px;
	}

	&_ttl {
		margin-top: 70px;
		color: v.$gold;
		font-family: "Roboto", serif;
		font-weight: 500;
		font-size: 32px;
		text-align: center;
		@include m.pc {
			margin-top: 138px;
			font-size: 65px;
		}
	}

	&_lead {
		margin-top: 16px;
		font-size: 20px;
		text-align: center;
		@include m.pc {
			font-size: 25px;
		}

		&_accent {
			color: v.$red;
		}
	}

	&Group {

		&BtnWrap {
			margin-bottom: 32px;
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
			}

			&_btn {
				@include m.pc {
					margin: 0 1%;
					width: 48%;
				}
			}
		}
	}

	// 沿革100th
	&Outline {
		position: relative;
		padding-top: 1px !important;
		@include m.pc {
			padding-bottom: 0 !important;
		}

		img {
			max-width: 100%;
		}

		&_wrap {
			overflow: hidden;
		}

		&_mv {
			text-align: center;
			@include m.sp {
				position: relative;
				z-index: +1;
				margin-top: -100px;
			}
			@include m.pc {
				margin-top: -280px;
			}
			img {
				max-width: 100%;
			}
		}

		@for $i from 1 through 9 {
			&_deco0#{$i} {
				position: absolute;
				pointer-events: none;
				@include m.sp {
					display: none;
				}
			}
		}
		@for $i from 10 through 14 {
			&_deco#{$i} {
				position: absolute;
				pointer-events: none;
				@include m.sp {
					display: none;
				}
			}
		}

		&_deco01 {
			top: 1%;
			left: 5%;
		}
		&_deco02 {
			top: 3%;
			right: 0%;
		}
		&_deco03 {
			bottom: -120px;
			left: 0%;
		}
		&_deco04 {
			left: -40%;
			bottom: -120px;
		}
		&_deco05 {
			right: -45%;
			bottom: -210px;
			*:lang(en) & {
				bottom: -250px;
			}
		}
		&_deco06 {
			left: 6%;
			bottom: -160px;
		}
		&_deco07 {
			left: 0;
			bottom: -180px;
		}
		&_deco08 {
			left: -30%;
			bottom: -190px;
		}
		&_deco09 {
			right: -28%;
			bottom: -230px;
		}
		&_deco10 {
			left: 5%;
			bottom: -180px;
		}
		&_deco11 {
			left: -20%;
			bottom: -170px;
			*:lang(en) & {
				bottom: -220px;
			}
		}
		&_deco12 {
			left: 10%;
			bottom: -120px;
		}
		&_deco13 {
			left: 0%;
			bottom: -144px;
		}
		&_deco14 {
			right: -26%;
			bottom: -210px;
			*:lang(en) & {
				bottom: -280px;
			}
		}

		&Modal {
			&.remodal {
				border-radius: 20px;
				max-width: 1116px;
				@include m.pc {
					border-radius: 39px;
					padding: 0;
				}
			}

			&_close {
				position: absolute;
				top: 15px;
				right: 15px;
				width: 15px;
				height: 15px;
				background: url(/company/outline/img/icon_close.png) no-repeat center / contain;
				@include m.pc {
					top: 30px;
					right: 30px;
					width: 30px;
					height: 30px;
					cursor: pointer;
				}
			}

			&_content {
				@include m.pc {
					display: flex;
					align-items: flex-end;
					gap: 0 4%;
					margin-right: 80px;
				}
			}

			&_pic {
				@include m.pc {
					overflow: hidden;
					border-radius: 39px 0 0 39px;
					width: 50%;
				}
			}

			&_body {
				text-align: left;

				@include m.sp {
					margin-top: 32px;
				}

				@include m.pc {
					width: 46%;
				}
			}

			&_year {
				color: v.$gold;
				font-family: "Josefin Sans", serif;
				font-weight: 300;
				font-size: 30px;
				@include m.pc {
					font-size: 61px;
				}
			}

			&_ttl {
				margin-top: 4px;
				color: v.$gold;
				font-size: 18px;
				font-weight: bold;
				@include m.pc {
					margin-top: 10px;
					font-size: 22px;
				}
			}

			&_lead {
				margin-top: 4px;
				font-weight: bold;
				@include m.pc {
					font-size: 18px;
				}
			}

			&_timeline {
				overflow-y: scroll;
				margin-top: 16px;
				height: 350px;
				@include m.pc {
					margin-top: 32px;
				}
				*:lang(en) & {
					height: 300px;
				}
			}
		}

		&Timeline {
			&_year {
				margin-top: 16px;
				font-weight: bold;
				@include m.pc {
					margin-top: 30px;
					font-size: 20px;
				}
				&:first-of-type {
					margin-top: 0;
				}
				> span {
					font-size: 11px;
					@include m.pc {
						font-size: 13px;
					}
				}
			}
			&_body {
				border-bottom: 1px solid #C8C8C8;
				margin-top: 16px;
				padding-bottom: 14px;
				font-size: 14px;
				@include m.pc {
					padding-bottom: 28px;
				}
			}
		}
	}

	&History {
		$this: &;
		position: relative;
		margin: 26px auto 0;
		padding: 1px 0 142px;
		max-width: 1040px;

		@include m.sp {
			margin-right: 5%;
			padding-left: 40px;
		}

		@include m.pc {
			padding-bottom: 284px;
		}

		&_item {
			margin-top: 142px;
			@include m.pc {
				display: flex;
				gap: 0 10%;
				margin-top: 284px;
			}
			&--left {
				@include m.pc {
					text-align: right;
				}
			}
			&--right {
				flex-direction: row-reverse;

				#{$this}_year {
					&::before {
						@include m.pc {
							left: auto;
							right: 100%;
						}
					}
					&::after {
						@include m.pc {
							left: auto;
							right: calc(100% + 23px);
						}
					}
				}
			}
		}

		&_body,
		&_slide {
			@include m.pc {
				position: relative;
				width: 45%;
			}
		}

		&_slider {
			overflow: hidden;
			@include m.sp {
				margin-top: 32px;
			}
			img {
				max-width: 100%;
			}
			.slick-dots {
				bottom: 7px;
				li {
					margin: 0 3px;
					width: 9px;
					height: 9px;
					button {
						width: 9px;
						height: 9px;
						&:before {
							border-radius: 50%;
							width: 9px;
							height: 9px;
							background: #FFF;
							content: "";
						}
					}
				}
			}
		}

		&_year {
			position: relative;
			color: v.$gold;
			font-family: "Josefin Sans", serif;
			font-weight: 300;
			font-size: 37px;
			@include m.pc {
				font-size: 72px;
			}
			&::before,
			&::after {
				position: absolute;
				top: 50%;
				transform: translate(0,-50%);
				content: "";
			}
			&::before {
				left: 100%;
				width: 67px;
				height: 1px;
				background: v.$red;
				@include m.sp {
					display: none;
				}
			}
			&::after {
				z-index: +1;
				background: url(/company/outline/img/icon_pot.png) no-repeat center / contain;
				@include m.sp {
					right: calc(100% + 3px);
					width: 40px;
					height: 24px;
				}
				@include m.pc {
					left: calc(100% + 23px);
					width: 58px;
					height: 36px;
				}
			}
		}

		&_ttl {
			margin-top: 16px;
			@include m.pc {
				font-size: 20px;
			}
		}

		&_txt {
			margin-top: 1em;
			font-size: 14px;
			text-align: left;
			line-height: 2;
		}

		&_btn {
			position: relative;
			display: block;
			border-radius: 15px;
			margin: 16px 0 0 auto;
			max-width: 165px;
			line-height: 30px;
			background: #000;
			color: #fff;
			font-size: 16px;
			text-align: center;
			@include m.pc {
				margin-top: 32px;
			}
			&::before {
				position: absolute;
				top: 50%;
				right: 10px;
				width: 11px;
				height: 11px;
				background: url(/company/outline/img/icon_plus.png) no-repeat center / contain;
				transform: translate(0,-50%);
				content: "";
			}
		}

		&_line {
			position: absolute;
			top: 0;
			left: 16px;
			bottom: 0;
			width: 2px;
			background: v.$red;
			@include m.pc {
				left: 50%;
				transform: translate(-50%,0);
			}
			&::before,
			&::after {
				position: absolute;
				top: 0;
				bottom: 0;
				width: 2px;
				background: v.$gold;
				transform: translate(-50%,0);
				content: "";
			}
			&::before {
				left: calc(50% - 7px);
			}
			&::after {
				left: calc(50% + 7px);
			}
			> span {
				position: absolute;
				bottom: 0;
				left: 50%;
				z-index: +1;
				width: 30px;
				height: 100px;
				background: linear-gradient(transparent, #fff);
				transform: translate(-50%,0);
			}

		}

		&2025 {
			margin: 0 auto;
			width: 90%;
			&_year {
				position: relative;
				color: v.$gold;
				font-family: "Josefin Sans", serif;
				font-weight: 300;
				font-size: 37px;
				text-align: center;
				@include m.pc {
					font-size: 72px;
				}
			}

			&_ttl {
				margin-top: 16px;
				text-align: center;
				@include m.pc {
					font-size: 20px;
				}
			}

			&_pic {
				margin-top: 19px;
				text-align: center;
				@include m.pc {
					margin-top: 38px;
				}
			}
		}

		&Message {
			margin: 30px auto 0;
			width: 90%;
			max-width: 1090px;
			@include m.pc {
				display: flex;
				align-items: center;
				gap: 0 2%;
				margin-top: 60px;
			}
			&_pic,
			&_body {
				@include m.pc {
					width: 50%;
				}
			}

			&_body {
				@include m.sp {
					margin-top: 32px;
				}
			}

			&_ttl {
				@include m.pc {
					font-size: 20px;
				}
			}

			&_txt {
				margin-top: 1em;
				font-size: 14px;
				line-height: 2;
			}

			&_name {
				margin-top: 1em;
				font-size: 14px;
			}

			&_sign {
				margin-top: 8px;
				max-width: 150px;
			}
		}
	}
}