@use 'global/mixin' as m;
@use 'global/variable' as v;

html.is-active {
  height: 100%;
  body {
    height: 100%;
  }
  #wrapper {
    position: fixed;
    top: 0;
  }
}

.l-container {
  margin: 0 5%;
  @include m.pc {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.l-mainCol {
  @include m.pc {
    order: 2;
    width: calc(100% - 330px);
    max-width: 930px;
  }
}

#titleArea.has-sidebar {
  @include m.sp {
    margin-top: 100px;
  }
}

.pc_none {
  @include m.pc {
    display: none !important;
  }
}
.sp_none {
  @include m.sp {
    display: none !important;
  }
}