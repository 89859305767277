html:lang(en) body {
  font-family: Arial,sans-serif;
}
html:lang(zh-cn) body {
  font-family:"Microsoft YaHei", "微软雅黑", sans-serif;
}
html:lang(zh-tw) body {
  font-family:"Microsoft JhengHei", "微軟正黑體" , sans-serif;
}

/* ブラウザのUAスタイルシートのすべてのスタイルを削除します、displayは除く */
*:where(:not(iframe, canvas, img, svg, video, select, input[type="checkbox"]):not(svg *)) {
  all: unset;
  display: revert;
}

/* box-sizingの優先値 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* リストのスタイル（箇条書き・番号）を削除します */
ol,
ul {
  list-style: none;
}

/* 画像がコンテナを超えないようにするため */
// img {
//   max-width: 100%;
// }

/* テーブルのセル間のスペースを削除します */
table {
  border-collapse: collapse;
}

a {
  cursor: pointer;
}
