@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-ttl {
	&01 {
		font-family: "Sharoa Pro ExtraBold";
		font-size: 28px;
		letter-spacing: .1em;
		line-height: 1;
		@include m.sp {
			text-align: center;
		}
			@include m.pc {
			font-size: 50px;
		}
		&_accent01 {
			color: v.$red;
		}
		&_accent02 {
			color: #065e78;
		}
		&_ttl {
			display: block;
			margin-top: .75em;
			color: v.$red;
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0;
			@include m.pc {
				font-size: 20px;
			}
			html:lang(en) & {
				font-family: "Poppins", san-serif;
				font-weight: 500;
			}
			html:lang(zh-cn) &,
			html:lang(zh-tw) & {
				font-family: 'Noto Sans SC', sans-serif;
				font-weight: 500;
			}
		}
	}
}