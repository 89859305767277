@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-anm {
  // フェードしながら上に移動
  &-Fiu {
    opacity: 0;
    transition: transform .8s, opacity .8s;
    transform: translate(0,100px);

    &.is-active {
      opacity: 1;
      transform: translate(0,0);
    }
  }

  // フェードしながら右に移動
  &-Fir {
    opacity: 0;
    transition: transform .8s, opacity .8s;
    transform: translate(-100px,0);

    &.is-active {
      opacity: 1;
      transform: translate(0,0);
    }

    &--pc {
      @include m.pc {
        opacity: 0;
        transition: transform .8s, opacity .8s;
        transform: translate(-100px,0);
      }

      &.is-active {
        @include m.pc {
          opacity: 1;
          transform: translate(0,0);
        }
      }
    }

    &--sp {
      @include m.sp {
        opacity: 0;
        transition: transform .8s, opacity .8s;
        transform: translate(-100px,0);
      }

      &.is-active {
        @include m.sp {
          opacity: 1;
          transform: translate(0,0);
        }
      }
    }
  }

  // フェードしながら左に移動
  &-Fil {
    opacity: 0;
    transition: transform .8s, opacity .8s;
    transform: translate(100px,0);

    &.is-active {
      opacity: 1;
      transform: translate(0,0);
    }

    &--pc {
      @include m.pc {
        opacity: 0;
        transition: transform .8s, opacity .8s;
        transform: translate(100px,0);
      }

      &.is-active {
        @include m.pc {
          opacity: 1;
          transform: translate(0,0);
        }
      }
    }

    &--sp {
      @include m.sp {
        opacity: 0;
        transition: transform .8s, opacity .8s;
        transform: translate(100px,0);
      }

      &.is-active {
        @include m.sp {
          opacity: 1;
          transform: translate(0,0);
        }
      }
    }
  }

  &-d-0\.4 {
    @include m.pc {
      transition-delay: .4s;
    }
  }

  &-d-0\.8 {
    @include m.pc {
      transition-delay: .8s;
    }
  }

  &-d-1\.2 {
    @include m.pc {
      transition-delay: 1.2s;
    }
  }
}
