@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-bg {
	&Gray2 {
		padding: 50px 5%;
		background: linear-gradient(to right, #f0f0f0 50%, #e5e5e5 50%);
		@include m.pc {
			padding: 100px 5%;
		}
	}
	&White {
		padding: 50px 5%;
		background: #FFF;
		@include m.pc {
			padding: 100px 5%;
		}
	}
}