@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-btn {
	&01 {
		$this: &;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		overflow: hidden;
		border: 2px solid #CCC;
		padding: 0 24px;
		height: 55px;
		background: #FFF;
		color: #333;
		font-size: 14px;
		text-align: center;
		@include m.pc {
			min-width: 300px;
			font-size: 16px;
			transition: color .4s;
		}
		&::before {
			@include m.pc {
				position: absolute;
				top: 0;
				left: -210%;
				width: 200%;
				height: 100%;
				background: v.$red;
				transform: skew(-45deg);
				content: "";
				transition: left .8s;
			}
		}
		&:hover {
			@include m.pc {
				color: #FFF;
				text-decoration: none;
			}
			&::before {
				@include m.pc {
					left: -50%;
				}
			}
			> span::before {
				@include m.pc {
					background-image: url(../img/icon_arrow_001_on.png);
				}
			}
		}
		> span {
			position: relative;
			padding-left: 20px;
			&::before {
				position: absolute;
				top: 50%;
				left: 0;
				width: 7px;
				height: 14px;
				background: url(../img/icon_arrow_001.png) no-repeat top left / contain;
				transform: translate(0,-50%);
				content: "";
			}
		}
		&--borderRed {
			@extend #{$this};
			border-color: v.$red;
		}
	}

	&02 {
		$this: &;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		overflow: hidden;
		border: 2px solid v.$red;
		padding: 0 24px;
		height: 55px;
		background: #FFF;
		color: #333;
		font-size: 14px;
		text-align: center;
		@include m.pc {
			font-size: 16px;
			transition: color .4s, background-color .4s;
		}
		> span {
			position: relative;
			padding-left: 20px;
			&::before {
				position: absolute;
				top: 50%;
				left: 0;
				width: 7px;
				height: 14px;
				background: url(../img/icon_arrow_001.png) no-repeat top left / contain;
				transform: translate(0,-50%);
				content: "";
			}
		}
		&:hover {
			@include m.pc {
				background: v.$red;
				color: #FFF;
				text-decoration: none;
			}
			&::before {
				@include m.pc {
					left: -50%;
				}
			}
			> span::before {
				@include m.pc {
					background-image: url(../img/icon_arrow_001_on.png);
				}
			}
		}
	}

	&Report {
		display: none;
		position: fixed;
		bottom: 44px;
		right: 5%;
		z-index: 9999;
		opacity: 0;
		pointer-events: none;
		border: 2px solid #fff;
		border-radius: 8px;
		padding: 16px 64px 16px 24px;
		background: #c4003a;
		font-size: 12px;
		line-height: 1.75;
		transition: opacity .4s;
		@include m.pc {
		bottom: 44px;
		right: 130px;
		font-size: 16px;
		}
		&.is-show {
			display: block;
		}
		&.is-active {
			opacity: 1;
			pointer-events: auto;
		}
		&_link {
			color: #FFF;
			font-weight: bold;
		}
		&_close {
			position: absolute;
			top: 50%;
			right: 16px;
			transform: translate(0,-50%);
			color: #FFF;
			font-size: 16px;
			&:hover {
				@include m.pc {
					text-decoration: none;
				}
			}
		}
	}
}