@use 'global/mixin' as m;
@use 'global/variable' as v;

$reasonImg: "../../reason/img/";

#reason {
  img {
    max-width: 100%;
  }
}

.p-reason {
	&_inner {
		margin: 0 auto;
		width: 90%;
		max-width: 1060px;
	}
	&Section01 {
		padding-bottom: 50px;
		background: url("#{$reasonImg}bg_01.png") no-repeat bottom right 5% / auto 92px;
		@include m.pc {
			padding-bottom: 100px;
			background-size: auto 184px;
		}
		&_txt {
			margin-bottom: 30px;
			@include m.pc {
				margin-bottom: 50px;
			}
		}
		&Col {
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
			}
			&_body {
				@include m.pc {
					order: 2;
					margin-top: 60px;
					width: 46%;
				}
			}
			&_pic {
				@include m.sp {
					margin-bottom: 30px;
				}
				@include m.pc {
					order: 1;
					margin-right: 80px;
					width: calc(54% - 80px);
					max-width: 490px;
				}
			}
		}
	}
	&Section02 {
		padding: 50px 0;
		background: #fff url("#{$reasonImg}bg_02.png") no-repeat bottom right 5% / auto 92px;
		@include m.pc {
			padding: 100px 0;
			background-size: auto 184px;
		}
		&_txt {
			margin-bottom: 30px;
			@include m.pc {
				margin-bottom: 40px;
			}
		}
		&_pic {
			text-align: center;
			@include m.sp {
				margin: 0 auto 30px;
				width: 90%;
			}
			@include m.pc {
				margin-bottom: 70px;
			}
		}
		&BtnList {
			@include m.sp {
				margin: 0 auto 40px;
				width: 90%;
			}
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-bottom: 60px;
			}
			&_btn {
				@include m.sp {
					margin-bottom: 20px;
				}
			}
		}
	}
	&Section03 {
		padding: 50px 0;
		background: #f0f0f0 url("#{$reasonImg}bg_03.png") no-repeat bottom right 5% / auto 92px;
		@include m.pc {
			padding: 100px 0;
			background-size: auto 184px;
		}
		&_txt {
			margin-bottom: 30px;
			@include m.pc {
				margin-bottom: 50px;
			}
		}
		&_btn {
			margin-bottom: 42px;
			@include m.sp {
				margin: 0 auto 20px;
				width: 90%;
			}
			&:last-of-type {
				@include m.pc {
					margin-bottom: 0;
				}
			}
		}
		&Col {
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
			}
			&_body {
				@include m.pc {
					margin-right: 80px;
					width: calc(54% - 80px);
				}
			}
			&_pic {
				@include m.sp {
					margin: 0 auto 30px;
					width: 80%;
				}
				@include m.pc {
					margin-top: 60px;
					width: 46%;
					max-width: 507px;
				}
			}
		}
	}
	&Group {
		position: relative;
		border: 2px solid v.$red;
		border-radius: 20px;
		margin: 0 auto;
		padding: 35px 5%;
		color: v.$red;
		@include m.pc {
			padding: 34px 80px 34px 340px;
			width: 90%;
			max-width: 1260px;
		}
		&_ttl {
			margin-bottom: 30px;
			font-size: 24px;
			font-weight: bold;
			letter-spacing: .1em;
			text-align: center;
			@include m.pc {
				margin-bottom: 60px;
				font-size: 50px;
			}
			&::before {
				width: 122px;
				height: 96px;
				background: url(../img/logo_red.png) no-repeat center / contain;
				content: "";
				@include m.sp {
					display: block;
					margin: 0 auto 30px;
				}
				@include m.pc {
					position: absolute;
					top: 0;
					left: 80px;
					width: 200px;
					height: 100%;
				}
			}
			> span {
				display: block;
				margin-top: 10px;
				font-size: 14px;
				letter-spacing: .05em;
				@include m.pc {
					margin-top: 20px;
					font-size: 16px;
				}
			}
		}
		&List {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 30px;
			&_item {
				position: relative;
				z-index: +1;
				border: 1px solid v.$red;
				width: 48%;
				@include m.pc {
					width: 30%;
				}
				&:nth-of-type(n+3) {
					@include m.sp {
						margin-top: 1rem;
					}
				}
				> img {
					width: 100%;
				}
			}
		}
	}
	&Related {
		padding: 50px 0;
		background: #fff;
		text-align: center;
		@include m.pc {
			padding: 100px 0;
		}
		&_ttl {
			margin-bottom: 30px;
			@include m.pc {
				margin-bottom: 60px;
			}
		}
		&List {
			$this: &;
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}
			&_item {
				display: block;
				position: relative;
				overflow: hidden;
				margin-bottom: 4px;
				font-size: 18px;
				font-weight: bold;
				@include m.pc {
					margin: 0 40px;
					width: 490px;
					font-size: 24px;
				}
				&::before {
					position: absolute;
					bottom: 15px;
					right: 15px;
					z-index: +1;
					border-radius: 50%;
					width: 25px;
					height: 25px;
					background: v.$red url(../img/icon_arrow_001_on.png) no-repeat center / 5px auto;
					content: "";
					@include m.pc {
						bottom: 20px;
						right: 30px;
						width: 30px;
						height: 30px;
						background-size: 7px auto;
					}
				}
				&:hover {
					@include m.pc {
						text-decoration: none;
					}
					#{$this}_pic {
						@include m.pc {
							transform: scale(1.2);
						}
					}
				}
				&:last-of-type {
					margin-bottom: 0;
				}
			}
			&_pic {
				width: 100%;
				@include m.pc {
					transition: transform .8s;
				}
			}
			&_ttl {
				position: absolute;
				top: 30px;
				left: 15px;
				padding-bottom: 20px;
				color: #FFF;
				@include m.pc {
					top: 60px;
					left: 30px;
				}
				&::before {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 30px;
					height: 2px;
					background: v.$red;
					content: "";
					@include m.pc {
						width: 60px;
						height: 3px;
					}
				}
			}
		}
	}
}