@use 'global/mixin' as m;
@use 'global/variable' as v;

$individualImg: "../../ir/img/individual/";

#ir.page-individual-data {
	img {
		max-width: 100%;
	}
	img:not(.icon):not(.inherit) {
		@include m.sp {
			width: auto;
		}
	}
}


.p-individual {
	&_inner {
		margin: 0 auto;
		width: 90%;
		max-width: 1060px;
	}
	&_num {
		$this: &;
		color: v.$red;
		font-family: 'Heebo', san-serif;
		line-height: 1;
		&--white {
			@extend #{$this};
			color: #fff;
		}
	}
	&_lead {
		color: v.$red;
		font-size: 16px;
		font-weight: bold;
		line-height: 1.75;
		text-align: center;
		@include m.pc {
			font-size: 20px;
		}
		&--caption {
			color: #333;
			font-size: 0.8em;
		}
	}
	&Data {
		&Sec01 {
			padding-bottom: 50px;
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				padding-bottom: 100px;
			}
			&_body {
				@include m.sp {
					margin-bottom: 20px;
				}
				@include m.pc {
					width: 50%;
				}
			}
			&_pic {
				@include m.pc {
					width: 50%;
				}
			}
			&_numLabel {
				font-size: 20px;
				font-weight: bold;
				text-align: center;
				@include m.pc {
					font-size: 30px;
				}
			}
			&_num {
				font-size: 40px;
				@include m.pc {
					font-size: 140px;
				}
			}
		}
		&Sec02 {
			padding: 50px 0;
			background: #fff;
			@include m.pc {
				padding: 100px 0;
			}
			&_inner {
				position: relative;
			}
			&_ttl {
				font-size: 20px;
				line-height: 1.5;
				@include m.sp {
					text-align: center;
				}
				@include m.pc {
					position: absolute;
					top: 0;
					right: 0;
					font-size: 30px;
				}
			}
			&_num {
				font-size: 40px;
				@include m.pc {
					font-size: 140px;
				}
			}
			&_homeNum,
			&_superNum {
				font-size: 20px;
				font-weight: bold;
				@include m.pc {
					font-size: 30px;
				}
			}
		}
		&Sec03 {
			padding: 50px 0;
			@include m.pc {
				padding: 100px 0;
			}
			&_ttl {
				color: #333;
				font-size: 18px;
				font-weight: bold;
				line-height: 1.75;
				text-align: center;
				@include m.sp {
					margin-bottom: 20px;
				}
				@include m.pc {
					position: absolute;
					bottom: 40px;
					left: 50%;
					font-size: 30px;
					transform: translate(-50%,0);
				}
			}
			&_circleOuter {
				@include m.pc {
					position: relative;
					margin-bottom: 40px;
					height: 715px;
				}
			}
			&_circle {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				width: 200px;
				height: 200px;
				background: #fff;
				text-align: center;
				@include m.sp {
					margin: 0 auto 20px;
				}
				@include m.pc {
					width: 400px;
					height: 400px;
				}
				&:nth-of-type(1) {
					@include m.pc {
						position: absolute;
						top: 0;
						left: 50%;
						transform: translate(-50%,0);
					}
				}
				&:nth-of-type(2) {
					@include m.pc {
						position: absolute;
						bottom: 0;
						left: 0;
					}
				}
				&:nth-of-type(3) {
					@include m.pc {
						position: absolute;
						bottom: 0;
						right: 0;
					}
				}
			}
			&_rank {
				position: absolute;
				top: 7%;
				left: 7%;
				border-radius: 50%;
				padding-top: .8em;
				width: 40px;
				height: 40px;
				background: v.$red;
				color: #fff;
				font-size: 12px;
				line-height: 1;
				text-align: center;
				@include m.pc {
					width: 80px;
					height: 80px;
					font-size: 24px;
				}
				> em {
					font-size: 20px;
					font-weight: bold;
					@include m.pc {
						font-size: 40px;
					}
				}
			}
			&_label {
				$this: &;
				margin-bottom: .5em;
				font-size: 10px;
				font-weight: bold;
				text-align: center;
				@include m.pc {
					font-size: 20px;
				}
			}
			&_numLabel {
				margin-bottom: .5em;
				font-size: 20px;
				font-weight: bold;
				text-align: right;
				@include m.pc {
					font-size: 30px;
				}
			}
			&_num {
				font-size: 40px;
				@include m.pc {
					font-size: 100px;
				}
			}
			&_icon {
				margin: 0 auto;
				text-align: center;
				@include m.sp {
					width: 100px;
				}
			}
		}
		&Sec04 {
			padding: 50px 5%;
			background: #fff;
			@include m.pc {
				padding: 100px 0;
			}
			&_ttl {
				margin-bottom: 20px;
				color: #333;
				font-size: 18px;
				font-weight: bold;
				line-height: 1.75;
				text-align: center;
				@include m.pc {
					font-size: 30px;
				}
			}
			&_list {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				@include m.pc {
					position: relative;
					margin-bottom: 40px;
				}
			}
			&_item {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 0 20px 20px 20px;
				padding: 16px;
				width: calc(50% - 5px);
				background: v.$red;
				color: #fff;
				text-align: center;
				@include m.sp {
					margin-bottom: 10px;
				}
				@include m.pc {
					width: calc(33% - 20px);
				}
				&:nth-of-type(1),
				&:nth-of-type(2) {
					@include m.pc {
						height: 530px;
					}
				}
				&:nth-of-type(3) {
					@include m.pc {
						align-self: baseline;
					}
				}
				&:nth-of-type(4) {
					@include m.pc {
						position: absolute;
						bottom: 0;
						right: 0;
					}
				}
			}
			&_label {
				margin-bottom: 1em;
				font-size: 14px;
				font-weight: bold;
				line-height: 1.5;
				text-align: center;
				@include m.pc {
					font-size: 20px;
				}
			}
			&_icon {
				margin: 0 auto 20px;
				text-align: center;
				@include m.pc {
					margin-bottom: 40px;
				}
				@include m.sp {
					width: 100px;
				}
			}
			&_num {
				font-size: 34px;
				@include m.pc {
					font-size: 90px;
				}
			}
			&_numLabel {
				display: inline-block;
				font-size: 20px;
				font-weight: bold;
				text-align: right;
				@include m.pc {
					font-size: 30px;
				}
			}
		}
		&Sec05 {
			padding: 50px 0;
			background: url(#{$individualImg}bg_icon.png) no-repeat center / cover;
			text-align: center;
			@include m.pc {
				padding: 100px 0;
			}
			&_ttl {
				margin-bottom: 20px;
				color: #333;
				font-size: 18px;
				font-weight: bold;
				line-height: 1.75;
				text-align: center;
				@include m.pc {
					font-size: 30px;
				}
			}
			&_num {
				font-size: 60px;
				@include m.pc {
					font-size: 100px;
				}
			}
			&_numLabel {
				margin-bottom: 1em;
				color: #333;
				font-size: 20px;
				font-weight: bold;
				@include m.pc {
					font-size: 30px;
				}
				> em {
					vertical-align: top;
				}
			}
		}
		&Sec06 {
			padding: 50px 0;
			background: #fff url(#{$individualImg}bg_japan.png) no-repeat top 5% center / 90% auto;
			text-align: center;
			@include m.pc {
				padding: 100px 0;
				background-position: center;
				background-size: auto 90%;
			}
			&_inner {
				@include m.sp {
					position: relative;
				}
				@include m.pc {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
				}
			}
			&_item {
				@include m.sp {
					width: 60%;
				}
				@include m.pc {
					margin: 0 40px;
				}
				&:nth-of-type(1) {
					@include m.sp {
						margin: 0 auto 0 0;
					}
				}
				&:nth-of-type(2) {
					@include m.sp {
						margin: 0 0 0 auto;
					}
				}
			}
			&_ttl {
				color: #333;
				font-size: 18px;
				font-weight: bold;
				line-height: 1.75;
				text-align: center;
				@include m.pc {
					margin-bottom: 20px;
					font-size: 30px;
				}
			}
			&_num {
				font-size: 80px;
				@include m.pc {
					font-size: 100px;
				}
			}
			&_numLabel {
				margin-bottom: 1em;
				color: #333;
				font-size: 20px;
				font-weight: bold;
				@include m.pc {
					font-size: 30px;
				}
				> em {
					vertical-align: top;
				}
			}
		}
		&Sec07 {
			padding: 50px 0;
			@include m.pc {
				padding: 100px 0;
			}
			&_inner {
				@include m.sp {
					position: relative;
				}
				@include m.pc {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}
			}
			&_body,
			&_pic {
				@include m.pc {
					width: calc(50% - 40px);
				}
			}
			&_body {
				@include m.pc {
					order: 2;
				}
			}
			&_pic {
				@include m.pc {
					order: 1;
				}
			}
			&_ttl {
				color: #333;
				font-size: 18px;
				font-weight: bold;
				line-height: 1.75;
				text-align: center;
				@include m.pc {
					margin-bottom: 20px;
					font-size: 30px;
				}
			}
			&_num {
				font-size: 80px;
				@include m.pc {
					font-size: 100px;
				}
			}
			&_numLabel {
				margin-bottom: 1em;
				color: #333;
				font-size: 20px;
				font-weight: bold;
				text-align: center;
				@include m.pc {
					font-size: 30px;
				}
				> em {
					vertical-align: top;
				}
			}
		}
		&Sec08 {
			padding: 50px 0;
			background: #fff;
			@include m.pc {
				padding: 100px 0;
			}
			&_inner {
				@include m.sp {
					position: relative;
				}
				@include m.pc {
					display: flex;
					flex-wrap: wrap;
					align-items: flex-end;
					justify-content: center;
					margin-bottom: 40px;
				}
			}
			&_ttl {
				margin-bottom: 10px;
				color: #333;
				font-size: 18px;
				font-weight: bold;
				line-height: 1.75;
				text-align: center;
				@include m.pc {
					margin-bottom: 20px;
					font-size: 30px;
				}
			}
			&_label {
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				border-radius: 50%;
				width: 78px;
				height: 78px;
				background: v.$red;
				color: #fff;
				font-size: 12px;
				font-weight: bold;
				line-height: 1.75;
				@include m.sp {
					margin-right: 20px;
				}
				@include m.pc {
					position: relative;
					top: -40px;
					margin-right: 40px;
					width: 150px;
					height: 150px;
					font-size: 20px;
				}
			}
			&_num {
				font-size: 80px;
				@include m.pc {
					font-size: 100px;
				}
			}
			&_numLabel {
				color: #333;
				font-size: 20px;
				font-weight: bold;
				text-align: center;
				@include m.sp {
					margin-bottom: 1em;
				}
				@include m.pc {
					font-size: 30px;
				}
			}
			&_pic {
				display: flex;
				flex-wrap: wrap;
				@include m.sp {
					justify-content: center;
					margin-bottom: 20px;
				}
			}
			&_icon {
				@include m.sp {
					width: 50%;
				}
			}
		}
		&Graph {
			position: relative;
			margin: 0 auto;
			@include m.sp {
				width:320px;
				height:320px;
			}
			&_graph {
				@include m.sp {
					position: absolute;
					top: -40px;
					left: -40px;
					width:320px;
					height:320px;
					transform: scale(0.5);
				}
				@include m.pc {
					position: relative;
					margin:0 auto 40px;
					width:460px;
					height:460px;
				}
			}
			&_list {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				font-weight:bold;
				color:#fff;
				line-height:1.4;
				.percentage {
					// display:none;
					color: #333;
					font-size: 20px;
					> em {
						color: v.$red;
						font-size: 40px;
					}
				}
				.lb {
					display:block;
				}
				> li {
					display:none;
					position:absolute;
					&.homeCenter,
					&.super {
						.percentage {
							font-size: 30px;
							> em {
								font-size: 100px;
							}
						}
					}
					&.homeCenter {
						top: 170px;
						left: 276px;
						padding-top: 78px;
						width: 130px;
						background: transparent url(#{$individualImg}icon_homecenter.png) no-repeat 28px 0 / 72px auto;
						font-size: 16px;
						text-align: center;
						.percentage {
							position: absolute;
							top: -220px;
							left: 120px;
							@include m.pc {
								top: 0;
								left: 204px;
							}
						}
					}
					&.super {
						top: 280px;
						left: 108px;
						padding-top: 64px;
						width: 90px;
						background:transparent url(#{$individualImg}icon_market.png) no-repeat 20px 0 / 60px auto;
						font-size: 16px;
						text-align: center;
						.percentage {
							position: absolute;
							top: 70px;
							left: -200px;
							@include m.pc {
								top: 0;
								left: -260px;
							}
						}
					}
					&.mailOrder {
						top: 192px;
						left: 15px;
						width: 70px;
						font-size: 16px;
						.percentage {
							position: absolute;
							top: -24px;
							left: -90px;
							@include m.pc {
								top: -24px;
								left: -90px;
							}
						}
					}
					&.retail {
						top: 134px;
						left: 30px;
						width:80px;
						font-size: 16px;
						text-align:center;
						.percentage {
							position: absolute;
							top: -40px;
							left: -72px;
							@include m.pc {
								top: -46px;
								left: -82px;
							}
						}
					}
					&.etc {
						top: 90px;
						left: 66px;
						width: 70px;
						font-size: 16px;
						.percentage {
							position: absolute;
							top: -60px;
							left: -70px;
							@include m.pc {
								top: -60px;
								left: -70px;
							}
						}
					}
					&.group {
						top: 40px;
						left: 148px;
						width:70px;
						font-size: 16px;
						text-align:center;
						.percentage {
							position: absolute;
							top: -80px;
							left: -60px;
							@include m.pc {
								top: -90px;
								left: -40px;
							}
						}
					}
				}
			}
		}
	}
}
