@use "global/mixin" as m;
@use "global/variable" as v;

$sustainImg: "../../sustainability/img/";
$messageImg: "../../sustainability/message/img/";

#sustainability {
	img {
		max-width: 100%;
	}
}

.p-sustain {
	&_inner {
		margin: 0 auto;
		width: 90%;
		max-width: 1060px;
	}
	&Icon {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 -6px 28px;
		@include m.pc {
			margin: 0 -12px 36px;
		}
		> img {
			@include m.sp {
				margin: 0 6px 12px;
				width: calc(25% - 12px) !important;
			}
			@include m.pc {
				margin: 0 12px 24px;
				width: 130px;
			}
		}
	}
	&Message {
		padding-bottom: 50px;
		@include m.pc {
			padding-bottom: 100px;
		}
		&_txt {
			margin-bottom: 30px;
			@include m.pc {
				margin-bottom: 50px;
			}
		}
		&_picOuter {
			@include m.pc {
				position: relative;
			}
		}
		&_pic {
			@include m.sp {
				margin-bottom: 30px;
			}
		}
		&_btn {
			@include m.pc {
				position: absolute;
				bottom: 25%;
				left: 5.8%;
			}
		}
	}
	&Approach {
		padding: 50px 0;
		background: #fff;
		@include m.pc {
			padding: 100px 0;
		}
		&_ttl {
			$this: &;
			position: relative;
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 20px;
			padding-bottom: 20px;
			@include m.sp {
				text-align: center;
			}
			@include m.pc {
				font-size: 30px;
			}
			&::before {
				position: absolute;
				bottom: 0;
				width: 60px;
				height: 3px;
				background: v.$red;
				content: "";
				@include m.sp {
					left: 50%;
					transform: translate(-50%,0);
				}
				@include m.pc {
					left: 0;
				}
			}
			&--center {
				@extend #{$this};
				text-align: center;
				&::before {
					left: 50%;
					transform: translate(-50%,0);
				}
			}
		}
		&_subttl {
			position: relative;
			margin-bottom: 20px;
			padding-left: 24px;
			font-size: 16px;
			font-weight: bold;
			@include m.pc {
				font-size: 20px;
			}
			&::before {
				position: absolute;
				left: 0;
				top: 50%;
				border-radius: 50%;
				width: 16px;
				height: 16px;
				background: v.$red;
				transform: translate(0,-50%);
				content: "";
			}
		}
		&_txt {
			margin-bottom: 30px;
			@include m.pc {
				margin-bottom: 60px;
			}
		}
		&_pic {
			margin-bottom: 30px;
			@include m.pc {
				margin-bottom: 60px;
			}
		}
		&_table {
			width: 100%;
			@include m.sp {
				overflow-x: auto;
			}
			> img {
				min-width: 1060px;
				max-width: none;
				width: auto;
			}
		}
		&Col {
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-bottom: 80px;
			}
			&--reverse {
				@include m.pc {
					display: flex;
					flex-wrap: wrap;
					flex-direction: row-reverse;
					justify-content: space-between;
					margin-bottom: 80px;
				}
			}
			&_body {
				@include m.pc {
					width: calc(50% - 40px);
				}
			}
			&_pic {
				@include m.sp {
					margin-bottom: 40px;
				}
				@include m.pc {
					width: calc(50% - 40px);
				}
				figcaption {
					margin-top: 15px;
					font-weight: bold;
					text-align: center;
				}
			}
		}
	}
	&Esg {
		position: relative;
		padding: 50px 0;
		@include m.pc {
			padding: 100px 0;
		}
		&::before {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 40%;
			background: #fff;
			content: "";
			@include m.pc {
				height: 360px;
			}
		}
		&_txt {
			margin-bottom: 30px;
			@include m.pc {
				margin-bottom: 60px;
			}
		}
		&CatList {
			$this: &;
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
			&_item {
				position: relative;
				border: 1px solid;
				border-radius: 0 20px 20px 20px;
				padding: 30px 5%;
				background: #fff;
				color: #333;
				text-decoration: none;
				@include m.sp {
					display: block;
					margin-bottom: 30px;
				}
				@include m.pc {
					padding: 30px 24px;
					width: calc(33.3% - 26px);
				}
				&::before {
					position: absolute;
					top: 0;
					left: 0;
					border: 32px solid;
					border-right-color: transparent;
					border-bottom-color: transparent;
					content: "";
					@include m.pc {
						border-width: 57px;
					}
				}
				&::after {
					position: absolute;
					top: 8px;
					left: 12px;
					color: #fff;
					font-family: "Sharoa Pro ExtraBold";
					font-size: 24px;
					@include m.pc {
						top: 16px;
						left: 24px;
						font-size: 34px;
					}
				}
				&:hover {
					text-decoration: none;
				}
				&--e {
					@extend #{$this}_item;
					border-color: #5f7806;
					&::before {
						border-top-color: #5f7806;
						border-left-color: #5f7806;
					}
					&::after {
						content: "E";
					}
					#{$this}_ttl {
						color: #5f7806;
						span::first-letter {
							color: #5f7806;
						}
					}
					#{$this}_txt::before {
						background-color: #5f7806;
					}
					.p-sustainEsgList_item::before {
						background-color: #5f7806;
					}
				}
				&--s {
					@extend #{$this}_item;
					border-color: #c4003a;
					&::before {
						border-top-color: #c4003a;
						border-left-color: #c4003a;
					}
					&::after {
						content: "S";
					}
					#{$this}_ttl {
						color: #c4003a;
						span::first-letter {
							color: #c4003a;
						}
					}
					#{$this}_txt::before {
						background-color: #c4003a;
					}
					.p-sustainEsgList_item::before {
						background-color: #c4003a;
					}
				}
				&--g {
					@extend #{$this}_item;
					border-color: #065e78;
					&::before {
						border-top-color: #065e78;
						border-left-color: #065e78;
					}
					&::after {
						content: "G";
					}
					#{$this}_ttl {
						color: #065e78;
						span::first-letter {
							color: #065e78;
						}
					}
					#{$this}_txt::before {
						background-color: #065e78;
					}
					.p-sustainEsgList_item::before {
						background-color: #065e78;
					}
				}
			}
			&_ttl {
				margin-bottom: 15px;
				font-size: 24px;
				font-weight: bold;
				text-align: center;
				@include m.pc {
					margin-bottom: 30px;
				}
				span {
					display: block;
					margin-top: 5px;
					color: #333;
					font-family: "Sharoa Pro ExtraBold";
					font-size: 16px;
				}
			}
			&_txt {
				position: relative;
				margin-bottom: 20px;
				padding-bottom: 20px;
				font-size: 16px;
				font-weight: bold;
				line-height: 1.5;
				text-align: center;
				@include m.pc {
					font-size: 20px;
				}
				&::before {
					position: absolute;
					bottom: 0;
					left: 50%;
					width: 60px;
					height: 3px;
					transform: translate(-50%,0);
					content: "";
				}
			}
		}
		&List {
			&_item {
				position: relative;
				margin-bottom: .5em;
				padding-left: 28px;
				font-size: 14px;
				line-height: 1.75;
				@include m.pc {
					font-size: 16px;
				}
				&::before {
					position: absolute;
					top: 5px;
					left: 0;
					border-radius: 50%;
					width: 20px;
					height: 20px;
					background: url(../img/icon_arrow_001_on.png) no-repeat center / 4px auto;
					content: "";
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	&Env {
		padding: 50px 0 0;
		background: #f0f0f0;
		@include m.pc {
			padding: 100px 0 0;
		}
		&_ttl {
			position: relative;
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 20px;
			padding-bottom: 20px;
			@include m.sp {
				text-align: center;
			}
			@include m.pc {
				font-size: 30px;
			}
			&::before {
				position: absolute;
				bottom: 0;
				width: 60px;
				height: 3px;
				background: #5f7806;
				content: "";
				@include m.sp {
					left: 50%;
					transform: translate(-50%,0);
				}
				@include m.pc {
					left: 0;
				}
			}
		}
		&_subttl {
			position: relative;
			margin-bottom: 20px;
			padding-left: 24px;
			font-size: 16px;
			font-weight: bold;
			@include m.pc {
				font-size: 20px;
			}
			&::before {
				position: absolute;
				left: 0;
				top: .2em;
				border-radius: 50%;
				width: 16px;
				height: 16px;
				background: #5f7806;
				content: "";
			}
		}
		&_txt {
			margin-bottom: 30px;
			@include m.pc {
				margin-bottom: 60px;
			}
			&--caution {
				font-size: 14px;
			}
		}
		&Policy {
			margin-bottom: 30px;
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-bottom: 60px;
			}
			&_item {
				border-radius: 0 20px 20px 20px;
				margin-bottom: 15px;
				padding: 20px 5%;
				background: #fff no-repeat bottom right / auto 72px;
				@include m.pc {
					margin-bottom: 48px;
					padding: 30px;
					width: calc(50% - 40px);
					min-height: 200px;
					background-size: auto 180px;
				}
				&:nth-child(1) {
					background-image: url(#{$sustainImg}img_01.png);
				}
				&:nth-child(2) {
					background-image: url(#{$sustainImg}img_02.png);
				}
				&:nth-child(3) {
					background-image: url(#{$sustainImg}img_03.png);
				}
				&:nth-child(4) {
					background-image: url(#{$sustainImg}img_04.png);
				}
			}
			&_ttl {
				margin-bottom: 10px;
				color: #5f7806;
				font-weight: bold;
				font-size: 16px;
				@include m.pc {
					font-size: 20px;
				}
			}
			&_txt {}
		}
		&Attempt {
			padding-bottom: 50px;
			@include m.pc {
				padding-bottom: 100px;
				background: url(#{$sustainImg}bg_environmental.png) no-repeat bottom right / 560px auto;
			}
			&::after {
				@include m.sp {
					display: block;
					margin: 40px 0 0 auto;
					width: 90%;
					padding-bottom: 73%;
					background: url(#{$sustainImg}bg_environmental.png) no-repeat bottom right / contain;
					content: "";
				}
			}
			&_inner {
				@include m.pc {
					max-width: 570px;
				}
			}
		}
		&List {
			margin-bottom: 30px;
			&_item {
				position: relative;
				margin-bottom: .5em;
				padding-left: 18px;
				font-size: 14px;
				font-weight: bold;
				line-height: 1.75;
				@include m.pc {
					font-size: 16px;
				}
				&::before {
					position: absolute;
					top: 7px;
					left: 0;
					border-radius: 50%;
					width: 11px;
					height: 11px;
					background: #949494;
					content: "";
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	&Social {
		padding: 50px 0;
		background: #fff;
		@include m.pc {
			padding: 100px 0;
		}
		&_ttl {
			$this: &;
			position: relative;
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 20px;
			padding-bottom: 20px;
			@include m.sp {
				text-align: center;
			}
			@include m.pc {
				font-size: 30px;
			}
			&::before {
				position: absolute;
				bottom: 0;
				width: 60px;
				height: 3px;
				background: v.$red;
				content: "";
				@include m.sp {
					left: 50%;
					transform: translate(-50%,0);
				}
				@include m.pc {
					left: 0;
				}
			}
			&--center {
				@extend #{$this};
				text-align: center;
				&::before {
					left: 50%;
					transform: translate(-50%,0);
				}
			}
		}
		&_subttl {
			position: relative;
			margin-bottom: 20px;
			padding-left: 24px;
			font-size: 16px;
			font-weight: bold;
			@include m.pc {
				font-size: 20px;
			}
			&::before {
				position: absolute;
				left: 0;
				top: .2em;
				border-radius: 50%;
				width: 16px;
				height: 16px;
				background: v.$red;
				content: "";
			}
		}
		&_accentTtl {
			margin-bottom: .5em;
			color: v.$red;
			font-weight: bold;
			@include m.pc {
				font-size: 18px;
			}
		}
		&_txt {
			margin-bottom: 30px;
			&--caution {
				font-size: 14px;
			}
		}
		&Col {
			margin-bottom: 30px;
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 60px;
			}
			&_pic {
				width: 130px;
				@include m.sp {
					margin: 0 auto 30px;
				}
				@include m.pc {
					margin-right: 60px;
				}
			}
			&_body {
				@include m.pc {
					width: calc(100% - 190px);
				}
			}
		}
		&List {
			margin-bottom: 30px;
			&_item {
				position: relative;
				margin-bottom: .5em;
				padding-left: 18px;
				font-size: 14px;
				font-weight: bold;
				line-height: 1.75;
				@include m.pc {
					font-size: 16px;
				}
				&::before {
					position: absolute;
					top: 7px;
					left: 0;
					border-radius: 50%;
					width: 11px;
					height: 11px;
					background: #949494;
					content: "";
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&ChildList {
			&_item {
				font-size: 14px;
				font-weight: normal;
				line-height: 2;
			}
		}
	}
	&Gov {
		padding: 50px 0;
		@include m.pc {
			padding: 100px 0;
		}
		&_ttl {
			position: relative;
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 20px;
			padding-bottom: 20px;
			@include m.sp {
				text-align: center;
			}
			@include m.pc {
				font-size: 24px;
			}
			&::before {
				position: absolute;
				bottom: 0;
				width: 60px;
				height: 3px;
				background: #065e78;
				content: "";
				@include m.sp {
					left: 50%;
					transform: translate(-50%,0);
				}
				@include m.pc {
					left: 0;
				}
			}
		}
		&_subttl {
			position: relative;
			margin-bottom: 20px;
			padding-left: 24px;
			font-size: 16px;
			font-weight: bold;
			@include m.pc {
				font-size: 20px;
			}
			&::before {
				position: absolute;
				left: 0;
				top: .2em;
				border-radius: 50%;
				width: 16px;
				height: 16px;
				background: #065e78;
				content: "";
			}
		}
		&_accentTtl {
			margin-bottom: .5em;
			color: #065e78;
			font-weight: bold;
			@include m.pc {
				font-size: 18px;
			}
		}
		&_txt {
			margin-bottom: 20px;
			@include m.pc {
				margin-bottom: 40px;
			}
			&--caution {
				font-size: 14px;
			}
		}
		&Col {
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
			&_item {
				@include m.pc {
					width: 490px;
				}
			}
		}
		&NumList {
			margin-bottom: 30px;
			counter-reset: num;
			@include m.pc {
				margin-bottom: 60px;
			}
			&_item {
				position: relative;
				margin-bottom: 0.5em;
				padding-left: 24px;
				font-weight: bold;
				line-height: 1.75;
				counter-increment: num;
				&::before {
					position: absolute;
					top: 6px;
					left: 0;
					border-radius: 50%;
					width: 16px;
					background: #949494;
					color: #fff;
					font-size: 12px;
					line-height: 16px;
					text-align: center;
					content: counter(num);
				}
			}
		}
		&List {
			margin-bottom: 30px;
			&_item {
				position: relative;
				margin-bottom: .5em;
				padding-left: 18px;
				font-size: 14px;
				font-weight: bold;
				line-height: 1.75;
				@include m.pc {
					font-size: 16px;
				}
				&::before {
					position: absolute;
					top: 7px;
					left: 0;
					border-radius: 50%;
					width: 11px;
					height: 11px;
					background: #949494;
					content: "";
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&ChildList {
			&_item {
				font-size: 14px;
				font-weight: normal;
				line-height: 2;
			}
		}
	}
}

.p-sustainTopMessage {
	&_outer {
		@include m.pc {
			background: url(#{$messageImg}bg_message_pc.png) no-repeat top center / cover;
		}
	}
	&_inner {
		margin: 0 auto;
		max-width: 1060px;
	}
	&_content {
		@include m.pc {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}
	&_body {
		@include m.sp {
			position: relative;
			z-index: +1;
			margin: 0 auto;
			width: 90%;
		}
		@include m.pc {
			width: 620px;
		}
	}
	&_pic {
		@include m.sp {
			margin-top: -100px;
		}
	}
	&_ttl {
		margin-bottom: 1em;
		font-size: 20px;
		font-weight: bold;
		line-height: 2;
		@include m.sp {
			border-left: 1px solid v.$red;
			padding-left: 1em;
		}
		@include m.pc {
			position: relative;
			font-size: 30px;
		}
		&::before {
			@include m.pc {
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 1px;
				background: v.$red;
				transform: translate(0,-50%);
				content: "";
			}
		}
	}
	&_txt {
		margin-bottom: 2em;
	}
	&_sign {
		@include m.sp {
			width: 115px !important;
		}
		@include m.pc {
			width: 230px;
		}
	}
}