@use 'global/mixin' as m;
@use 'global/variable' as v;

$aboutImg: "../../about/img/";

.p-about {
	&_inner {
		margin: 0 auto;
		width: 90%;
		max-width: 1060px;
	}

	// 中山福グループの事業ページ
	&Group {
		&Section001 {
			margin-bottom: 42px;
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-bottom: 84px;
			}
			&_body {
				@include m.pc {
					width: 540px;
				}
			}
			&_pic {
				@include m.pc {
					width: 420px;
				}
			}
		}
		&Section002 {
			margin-bottom: 42px;
			@include m.pc {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-bottom: 84px;
			}
			&_body {
				@include m.pc {
					order: 2;
					width: 540px;
				}
			}
			&_pic {
				@include m.pc {
					order: 1;
					width: 420px;
				}
			}
		}
	}
}