@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-contact {
	border-top: 2px solid v.$red;
	padding: 50px 5%;
	background: #FFF;
	text-align: center;
	@include m.pc {
		padding: 100px 5%;
	}
	&_ttl {
		margin-bottom: 30px;
		@include m.pc {
			margin-bottom: 60px;
		}
	}
	&List {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		&_item {
			@include m.sp {
				width: 100%;
			}
			&--tel {
				border: 2px solid v.$red;
				line-height: 55px;
				width: 300px;
				height: 55px;
				background: #FFF;
				color: #333;
				@include m.sp {
					margin-top: 15px;
					width: 100%;
				}
				@include m.pc {
					margin-left: 30px;
					pointer-events: none;
				}
				> span {
					display: inline-block;
					position: relative;
					margin-right: .5em;
					padding-left: 24px;
					color: v.$red;
					font-weight: bold;
					&::before {
						position: absolute;
						top: 50%;
						left: 0;
						width: 16px;
						height: 22px;
						background: url(../img/icon_tel_002.png) no-repeat top left / contain;
						transform: translate(0,-50%);
						content: "";
					}
				}
			}
		}
	}
}