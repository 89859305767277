@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-sidebar {
	@include m.sp {
		position: fixed;
		top: 50px;
		left: 0;
		z-index: 1000;
		width: 100%;
	}
	@include m.pc {
		order: 1;
		margin-right: 90px;
		width: 240px;
	}
	&_nav {
		@include m.sp {
			display: none;
			padding: 0 16px 40px;
			width: 100%;
			background: #f0f0f0;
		}
		html.is-active & {
			@include m.sp {
				display: block;
				height: calc(100vh - 100px);
				overflow-y: scroll;
			}
		}
	}
	&_trigger {
		display: block;
		position: relative;
		border-bottom: 3px solid v.$red;
		padding: 0 16px;
		width: 100%;
		line-height: 50px;
		background: #fff;
		color: #333;
		font-size: 18px;
		font-weight: bold;
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			background: v.$red;
			transform: translate(0,-50%);
			content: "";
		}
		&::before {
			right: 16px;
			width: 20px;
			height: 3px;
		}
		&::after {
			right: 24.5px;
			width: 3px;
			height: 20px;
		}
		&:hover {
			text-decoration: none;
		}
	}
	&_link {
		$this: &;
		display: block;
		border-bottom: 2px solid v.$red;
		padding: 13px 0;
		background: url(../img/icon_arrow_001.png) no-repeat right 10px center / 7px auto;
		color: #333;
		font-size: 18px;
		font-weight: bold;
		@include m.pc {
			transition: background .4s;
		}
		&[target="_blank"] {
			background: url(../img/icon_site_004.png) no-repeat right 8px center / 14px auto;
		}
		&:hover {
			@include m.pc {
				background-color: lighten(v.$red,55%);
				text-decoration: none;
			}
		}
		&--child {
			@extend #{$this};
			border-bottom: 1px solid v.$red;
			padding-left: .5em;
			font-size: 16px;
		}
		&--gChild {
			@extend #{$this};
			border-bottom: 1px solid #cfcfcf;
			padding-left: 1.5em;
			background-image: url(../img/icon_arrow_002.png);
			font-size: 14px;
			font-weight: normal;
		}
	}
	&_bnr {
		display: block;
		margin-bottom: 20px;
		@include m.pc {
			transition: opacity .4s;
		}
		&:hover {
			@include m.pc {
				opacity: .6;
			}
		}
		img {
			max-width: 100%;
		}
	}
	&LinkList {
		margin-bottom: 25px;
	}
}