@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-link {
	&[target="_blank"]::after {
		display: inline-block;
		width: 14px;
		height: 14px;
		background: url(../img/icon_site_004.png) no-repeat center / 14px auto;
		content: "";
	}
	&:hover {
		@include m.pc {
			text-decoration: none;
		}
	}
}